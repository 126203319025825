// Screen breakpoints //
export const breakpoints = {
  mobile: '320px',
  tablet: '768px',
  large: '960px',
  desktop: '1024px',
  extraLarge: '1200px',
  extraExtraLarge: '1536px',

  customMobile1: '420px'
}